<template>
  <div class="cart-payment-methods">
    <h1
      class="headline"
      v-html="$t('terminal.cart.paymentmethods.headline')"
    ></h1>

    <!-- cart:payment-methods START -->
    <div class="payment-methods" v-if="paymentCategories">

      <!-- cart:payment-methods:first START -->
      <div
        :class="[
          'payment-method',
          { 'pressed' : pressed === paymentCategoryIndex }
        ]"
        v-on:touchstart="touchStart($event, paymentCategoryIndex)"
        v-on:touchend="touchEnd($event, paymentCategoryIndex);"
        v-on:touchcancel="touchEnd($event, paymentCategoryIndex)"
        v-for="(paymentCategory, paymentCategoryIndex) in paymentCategories"
        :key="`payment-category-${paymentCategoryIndex}`"
      >
        <router-link
          class="f-link"
          :to="{ path: '/cart/app-payment',
            query: {
              activeStep: 2,
              paymentCategoryID: paymentCategory.id,
              paymentCategoryName: paymentCategory.name
            }
          }"
          v-if="paymentCategory.name === 'InApp'"
        ></router-link>
        <router-link
          class="f-link"
          :to="{ path: '/cart/checkout',
            query: {
              paymentCategoryID: paymentCategory.id,
              paymentCategoryName: paymentCategory.name
            }
          }"
          v-if="paymentCategory.name === 'InShop'"
        ></router-link>
        <div class="method-inner">
          <div class="method-image">
            <img
              :src="iconClientCard"
              alt="$t('terminal.cart.paymentmethods.methods.client.headline')"
              v-if="paymentCategory.name === 'InApp'"
            />
            <img
              :src="iconCreditCard"
              alt="$t('terminal.cart.paymentmethods.methods.credit.headline')"
              v-if="paymentCategory.name === 'InShop'"
            />
          </div>
          <div
            class="method-headline"
            v-html="$t('terminal.cart.paymentmethods.methods.app.headline')"
            v-if="paymentCategory.name === 'InApp'"
          ></div>
          <div
            class="method-headline"
            v-html="$t('terminal.cart.paymentmethods.methods.ec.headline')"
            v-if="paymentCategory.name === 'InShop'"
          ></div>
        </div>
      </div>
      <!-- cart:payment-methods:first END -->

      <!-- cart:payment-methods:first START
      <div
        :class="[
          'payment-method',
          { 'pressed' : pressed === 2 }
        ]"
        v-on:touchstart="touchStart($event, 2)"
        v-on:touchend="touchEnd($event, 2);"
        v-on:touchcancel="touchEnd($event, 2)"
      >
        <router-link
          class="f-link"
          :to="{ path: '/cart/app-payment', query: { activeStep: 2 } }"
        ></router-link>
        <div class="method-inner">
          <div class="method-image">
            <img
              :src="iconClientCard"
              alt="$t('terminal.cart.paymentmethods.methods.client.headline')"
            />
          </div>
          <div
            class="method-headline"
            v-html="'Mit der App bezahlen'"
          ></div>
        </div>
      </div>
      cart:payment-methods:first END -->

      <!-- cart:payment-methods:second START
      <div
        :class="[
          'payment-method',
          { 'pressed' : pressed === 1 }
        ]"
        v-on:touchstart="touchStart($event, 1)"
        v-on:touchend="touchEnd($event, 1);"
        v-on:touchcancel="touchEnd($event, 1)"
      >
        <router-link
          class="f-link"
          :to="{ path: '/cart/summary', query: { ecCard: true } }"
        ></router-link>
        <div class="method-inner">
          <div class="method-image">
            <img
              :src="iconCreditCard"
              alt="$t('terminal.cart.paymentmethods.methods.credit.headline')"
            />
          </div>
          <div
            class="method-headline"
            v-html="'Mit EC Karte'"
          ></div>
        </div>
      </div>
      cart:payment-methods:second END -->

    </div>
    <!-- cart:payment-methods END -->

    <!-- index:qr-code:ec START -->
    <div class="help-ec">
      <div class="help-ec-inner">
        <div class="actions">
          <!-- abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
          >
            <span v-html="$t('terminal.index.cart.purchase.abort')"></span>
          </div>
          <!-- abort END -->
        </div>
      </div>
    </div>
    <!-- index:qr-code:ec END -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ICONS from '../../utils/icons';

const {
  iCartCredit,
  iCartClient,
} = ICONS;

export default {
  name: 'cart-payment-methods',
  data() {
    return {
      iconCreditCard: iCartCredit.defaultUrl,
      iconClientCard: iCartClient.defaultUrl,
      pressed: -1,
      /**
       * Box
       */
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',
    };
  },
  async created() {
    // if boxToxen then fetch box
    if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
      this.box = await this.FETCH_BOX();
    }

    // fetch payment categories
    await this.setPaymentCategories();
  },
  computed: {
    ...mapGetters({
      paymentCategories: 'paymentCategories',
    }),

    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },
  },
  methods: {
    ...mapActions([
      'setBox',
      'clearCart',
      'setPaymentCategories',
    ]),

    async FETCH_BOX() {
      await this.setBox({
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.box;
    },

    touchStart(event, i) {
      event.target.classList.add('pressed');
      this.pressed = i;
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
      this.pressed = -1;
    },

    async resetStoreCart() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('anybox_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },
  },
};
</script>
